import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { getToken, unauthorizedRedirect } from "../utils/getToken";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";

const DataTable = () => {
  const [data, setData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(""); // Selected country
  const [selectedPosition, setSelectedPosition] = useState("");
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const pageSizeOptions = [10, 25, 50, 100, 500];
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({}); // State for column filters
  const [showTable, setShowTable] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);

  const _loadCountrySuggestions = (query, callback) => {
      countryOptions(query)
        .then((res) => {
          callback(res)
        })
        .catch((e) => {
          callback([])
        })
  };

  const _loadPositionSuggestions = (query, callback) => {
    positionOptions(query)
      .then((res) => {
        callback(res);
      })
      .catch(e => {
        callback([])
      })
  } 

  const countryOptions = (search) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/countries?search=${search}`)
        .then((response) => {
          const countryOptions = response.data.data.map((ele) => ({
            label: ele.countryName,
            value: ele.countryName,
          }));
          resolve(countryOptions);
        })
        .catch((error) => {
          // Handle error appropriately
          setSelectedCountry("");
          reject();
        });
    });

  const loadCountries = React.useCallback(debounce(_loadCountrySuggestions, 700), []);
  const loadPossitions = React.useCallback(debounce(_loadPositionSuggestions, 700), []);

  const positionOptions = (search) =>
    new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/positions?search=${search}`)
        .then((response) => {
          const positionOptions = response.data.data.map((ele) => ({
            label: ele.positionName,
            value: ele.positionName,
          }));
          resolve(positionOptions);
        })
        .catch((error) => {
          // Handle error appropriately
          setSelectedPosition("");
          reject();
        });
    });
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [from, to, itemsPerPage]);

  const fetchData = () => {
    setLoading(true);
    const queryParams = new URLSearchParams({
      from: from,
      to: to,
      ...filters, // Include filters in query params
    });

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/marketings?${queryParams}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        setTotalPages(Math.ceil(response.data.count / itemsPerPage));
      })
      .catch((error) => {
        if (error.response.status == 404) {
          setData(error.response.data.data);
        }
        if (error.response.status == 401) {
          unauthorizedRedirect(navigate);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePagination = (newFrom, newTo) => {
    setFrom(newFrom);
    setTo(newTo);
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setItemsPerPage(newSize);
    setFrom(0); // Reset to the first page when changing page size
    setTo(newSize);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleFilterApply = () => {
    setFrom(0);
    fetchData();
    setShowTable(true);
  };

  const toggleFilter = () => {
    setShowFilters(!showFilters);
  };

  const downloadFile = () => {
    setIsDownloading(true);
    const queryParams = new URLSearchParams({
      from: from,
      to: to,
      ...filters, // Include filters in query params
    });

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/marketings/export/?${queryParams}`,
        {
          // responseType: "blob", // This sets the expected response type to 'blob'
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then(async (response) => {
        // const url = window.URL.createObjectURL(response.data);
        const url = response.data.file;
        //console.log(url);
        const link = document.createElement("a");
        link.href = url;
        link.download = "marketing-export.csv"; // Set the desired file name
        link.click();

        // Clean up the temporary URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          unauthorizedRedirect(navigate);
        }
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <div className="bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Marketing</h2>
          <div className="mb-1">
            Page Size:{" "}
            <select
              value={itemsPerPage}
              onChange={handlePageSizeChange}
              className="border rounded px-2 py-1"
            >
              {pageSizeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="overflow-x-auto">
          <div className="mb-1">
            <div className="flex justify-end">
              <button
                className="mr-3 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                onClick={toggleFilter}
                style={{ height: "40px", width: "90px" }}
              >
                Filters
              </button>
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                onClick={downloadFile}
                disabled={isDownloading}
                style={{ height: "40px", width: "90px" }}
              >
                {isDownloading ? (
                  <div className="flex justify-center py-4">
                    <div
                      className="spinner"
                      style={{
                        "margin-top": "-16px",
                        "border-left-color": "white",
                      }}
                    ></div>
                  </div>
                ) : (
                  <>Export</>
                )}
              </button>
            </div>
            {showFilters && (
              <div
                className={`transition-all ease-out duration-300 opacity-100 mb-3`}
              >
                <input
                  type="text"
                  className="px-2 my-1 py-3 border rounded mr-2"
                  placeholder="Name"
                  onChange={(e) => handleFilterChange("name", e.target.value)}
                />
                <input
                  type="text"
                  className="px-2 my-1 py-3 border rounded mr-2"
                  placeholder="Email"
                  onChange={(e) => handleFilterChange("email", e.target.value)}
                />
                <input
                  type="text"
                  className="px-2 my-1 py-3 border rounded mr-2"
                  placeholder="Phone"
                  onChange={(e) => handleFilterChange("phone", e.target.value)}
                />
                <input
                  type="text"
                  className="px-2 my-1 py-3 border rounded mr-2"
                  placeholder="LinkedIn"
                  onChange={(e) =>
                    handleFilterChange("linkedin", e.target.value)
                  }
                />
                <input
                  type="text"
                  className="px-2 my-1 py-3 border rounded mr-2"
                  placeholder="Company LinkedIn"
                  onChange={(e) =>
                    handleFilterChange("linkedin_company_page", e.target.value)
                  }
                />
                <input
                  type="text"
                  className="px-2 my-1 py-3 border rounded mr-2"
                  placeholder="Company"
                  onChange={(e) =>
                    handleFilterChange("company", e.target.value)
                  }
                />
                <input
                  type="text"
                  className="px-2 my-1 py-3 border rounded mr-2"
                  placeholder="Company Phone"
                  onChange={(e) =>
                    handleFilterChange("company_phone", e.target.value)
                  }
                />
                <input
                  type="text"
                  className="px-2 my-1 py-3 border rounded mr-2"
                  placeholder="Website"
                  onChange={(e) =>
                    handleFilterChange("website", e.target.value)
                  }
                />
                <input
                  type="text"
                  className="px-2 my-1 py-3 border rounded mr-2"
                  placeholder="Domain"
                  onChange={(e) => handleFilterChange("domain", e.target.value)}
                />
                <input
                  type="text"
                  className="px-2 my-1 py-3 border rounded mr-2"
                  placeholder="Facebook"
                  onChange={(e) =>
                    handleFilterChange("facebook", e.target.value)
                  }
                />
                <input
                  type="text"
                  className="px-2 my-1 py-3 border rounded mr-2"
                  placeholder="Twitter"
                  onChange={(e) =>
                    handleFilterChange("twitter", e.target.value)
                  }
                />
                <input
                  type="text"
                  className="px-2 my-1 py-3 border rounded mr-2"
                  placeholder="State"
                  onChange={(e) => handleFilterChange("state", e.target.value)}
                />
                <div className="px-2 my-1 py-3 rounded mr-2">
                  <div className="flex space-x-4">
                    {" "}
                    {/* Use flex to display them on the same line */}
                    <div className="relative flex items-center space-x-2">
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={loadCountries}
                        value={
                          selectedCountry
                            ? { label: selectedCountry, value: selectedCountry }
                            : null
                        }
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            setSelectedCountry(selectedOption.value);
                            handleFilterChange("country", selectedOption.value);
                          } else {
                            setSelectedCountry("");
                          }
                        }}
                        noOptionsMessage={() => "No countries found"}
                        className="w-40" // Set the width of the select input
                        placeholder="Country"
                      />
                      {selectedCountry && (
                        <button
                          onClick={() => {
                            setSelectedCountry("");
                            handleFilterChange("country", "");
                          }}
                          className="bg-blue-400 text-white px-2 py-1 rounded-full"
                        >
                          Clear
                        </button>
                      )}
                    </div>
                    <div className="relative flex items-center space-x-2 mx-10">
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={loadPossitions}
                        value={
                          selectedPosition
                            ? {
                                label: selectedPosition,
                                value: selectedPosition,
                              }
                            : null
                        }
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            setSelectedPosition(selectedOption.value);
                            handleFilterChange(
                              "position",
                              selectedOption.value
                            );
                          } else {
                            setSelectedPosition("");
                          }
                        }}
                        noOptionsMessage={() => "No positions found"}
                        className="w-40" // Set the width of the select input
                        placeholder="Position"
                      />
                      {selectedPosition && (
                        <button
                          onClick={() => {
                            setSelectedPosition("");
                            handleFilterChange("position", "");
                          }}
                          className="bg-blue-400 text-white px-2 py-1 rounded-full"
                        >
                          Clear
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-3 rounded"
                  onClick={handleFilterApply}
                >
                  Apply Filters
                </button>
              </div>
            )}
          </div>

          { 
          loading ? (
            <div className="flex justify-center py-4">
              <div className="spinner"></div>
            </div>
          ) : (
            <>
            <table className="w-full table-fixed border-collapse border min-w-[800px]">
              <thead>
                <tr className="bg-gray-200">
                  <th className="w-1/6 border p-2">Name</th>
                  <th className="w-1/6 border p-2">Email</th>
                  <th className="w-1/6 border p-2">Phone</th>
                  <th className="w-1/6 border p-2">LinkedIn</th>
                  <th className="w-1/6 border p-2">Linkedin Company Page</th>
                  <th className="w-1/6 border p-2">Position</th>
                  <th className="w-1/6 border p-2">Company</th>
                  <th className="w-1/6 border p-2">Company Phone</th>
                  <th className="w-1/6 border p-2">Website</th>
                  <th className="w-1/6 border p-2">Domain</th>
                  <th className="w-1/6 border p-2">Facebook</th>
                  <th className="w-1/6 border p-2">Twitter</th>
                  <th className="w-1/6 border p-2">Country</th>
                  <th className="w-1/6 border p-2">State</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-gray-100" : ""}
                  >
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      {item.name && item.name.toLowerCase() == "null"
                        ? "-"
                        : item.name}
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      {item.email && item.email.toLowerCase() == "null"
                        ? "-"
                        : item.email}
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      {item.phone && item.phone.toLowerCase() == "null"
                        ? "-"
                        : item.phone}
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      <a
                        href={item.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.linkedin && item.linkedin.toLowerCase() == "null"
                          ? "-"
                          : item.linkedin}
                      </a>
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      <a
                        href={item.linkedin_company_page}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.linkedin_company_page &&
                        item.linkedin_company_page.toLowerCase() == "null"
                          ? "-"
                          : item.linkedin_company_page}
                      </a>
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      {item.position && item.position.toLowerCase() == "null"
                        ? "-"
                        : item.position}
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      {item.company && item.company.toLowerCase() == "null"
                        ? "-"
                        : item.company}
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      {item.company_phone &&
                      item.company_phone.toLowerCase() == "null"
                        ? "-"
                        : item.company_phone}
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      <a
                        href={item.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.website && item.website.toLowerCase() == "null"
                          ? "-"
                          : item.website}
                      </a>
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      <a
                        href={item.domain}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.domain && item.domain.toLowerCase() == "null"
                          ? "-"
                          : item.domain}
                      </a>
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      <a
                        href={item.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.facebook && item.facebook.toLowerCase() == "null"
                          ? "-"
                          : item.facebook}
                      </a>
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      <a
                        href={item.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.twitter && item.twitter.toLowerCase() == "null"
                          ? "-"
                          : item.twitter}
                      </a>
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      {item.country && item.country.toLowerCase() == "null"
                        ? "-"
                        : item.country}
                    </td>
                    <td className="w-1/6 border p-2 whitespace-normal break-all text-center">
                      {item.state && item.state.toLowerCase() == "null"
                        ? "-"
                        : item.state}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-center mt-4">
            <div className="mx-4">
              Page {from / itemsPerPage + 1} of {totalPages}
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() =>
                handlePagination(
                  Math.max(0, from - itemsPerPage),
                  to - itemsPerPage
                )
              }
            >
              Previous
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
              onClick={() =>
                handlePagination(from + itemsPerPage, to + itemsPerPage)
              }
            >
              Next
            </button>
          </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataTable;
