import React from 'react';
import Login from './components/Login';
import Home from './components/Home';
import { Route, Routes } from "react-router-dom";
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <>
      <Routes>
        <Route path="/login" element = {<Login></Login>}/>
        <Route element={<PrivateRoute />}>
          <Route path="/" element = {<Home></Home>}/>
        </Route>
      </Routes>
    </>
  );
}

export default App;
