import React, { useEffect, useState } from "react";
import { FaGoogle } from "react-icons/fa"; // Import the Google icon from react-icons
import axios from "axios";``
import { isAuth } from "../utils/getToken";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth()) {
      navigate('/')
    };
  }, [])

  const handleLogin = (e) => {
    e.preventDefault();

    // try {
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        {
          email,
          password,
        }
      )
        .then((res) => {
          console.log("data", res.data);
          Cookies.set('token', res.data.data);
          navigate('/');
        })
        .catch((err) => {
          console.log(err);
          // alert(err.response.data?.message);
        });
      // if (response.data) {
      //   console.log(response.data);
      //   // Login successful, handle redirection or further actions
      // } else {
      //   // Login failed, handle error
      // }
    // } catch (error) {
    //   console.log(error);
    //   // Handle network or other errors
    // }
  };

  const handleGoogleLogin = (e) => {
    e.preventDefault();
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google`;
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-4">Login</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              className="w-full border rounded px-3 py-2 focus:outline-none focus:border-blue-400"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              className="w-full border rounded px-3 py-2 focus:outline-none focus:border-blue-400"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white w-full py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
          >
            Log in
          </button>
          <div className="flex items-center mt-4">
            <hr className="flex-grow border-gray-300" />
            <span className="mx-4 text-gray-600">or</span>
            <hr className="flex-grow border-gray-300" />
          </div>

          <button
            onClick={handleGoogleLogin}
            type="button"
            className="bg-red-600 text-white w-full py-2 rounded mt-4 flex items-center justify-center hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-300"
          >
            <FaGoogle className="mr-2" /> Log in with Google
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
