import Cookies from "js-cookie";

export const isAuth = () => {
  const token = Cookies.get("token");
  if (token) {
    return true;
  }
  return false;
};

export const unauthorizedRedirect = (navigate) => {
  Cookies.remove("token");
  navigate("/login");
};

export const getToken = () => {
  const token = Cookies.get("token");
  return token;
};
